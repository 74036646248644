import React from "react";
import { graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { AnchorLink } from "gatsby-plugin-anchor-links";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import FAQs from "../components/Repeating/FAQs";
import ValueProps from "../components/Repeating/ValueProps";
import Testimonials from "../components/Repeating/Testimonials";
import RelatedLoanPrograms from "../components/Repeating/RelatedLoanPrograms";
import About from "../components/Repeating/About";
import CallToAction from "../components/Repeating/CTA";
import ButtonSolid from "../components/Button/ButtonSolid";
import ScrollNavigation from "../components/Scroll/ScrollNavigation";

import affordableInterestRates from "../images/2.0 Loan Programs/FHA/Affordable Interest Rates.svg";
import easierQualification from "../images/2.0 Loan Programs/FHA/Easier Qualification.svg";
import lowDownPayment from "../images/2.0 Loan Programs/FHA/Low Down Payment.svg";
import sellerPaidClosingCosts from "../images/2.0 Loan Programs/FHA/Seller-Paid Closing Costs.svg";

export const query = graphql`
  query CityFHATemplateQuery($id: String!) {
    loanProgram: sanityCityLoanPrograms(id: { eq: $id }) {
      id
      city {
        title
      }
      seoTitle
      metaDescription
      loanProgram
    }
    openGraphImage: file(
      relativePath: { eq: "Open Graph/Facebook/FHA Home Loans.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "Open Graph/Twitter/FHA Home Loans.jpg" }
    ) {
      publicURL
    }
  }
`;

const Template = ({ data }) => {
  const url = typeof window !== "undefined" ? window.location.pathname : "";

  const faqs = [
    {
      question: "What housing markets do you serve?",
      answer: (
        <>
          <p className="mb-0">
            We serve eight states, with areas as diverse as{" "}
            <Link
              to="/tempe-fha-loans/"
              className="font-normal text-typography-body no-underline"
            >
              Tempe
            </Link>
            , Arizona,{" "}
            <Link
              to="/las-vegas-fha-loans/"
              className="font-normal text-typography-body no-underline"
            >
              Las Vegas
            </Link>
            , Nevada, and{" "}
            <Link
              to="/san-bernardino-fha-loans/"
              className="font-normal text-typography-body no-underline"
            >
              San Bernardino
            </Link>
            , California.
          </p>
        </>
      ),
    },
    {
      question:
        "Are FHA loans only for low-income borrowers or first-time home buyers?",
      answer: (
        <>
          <p className="mb-0">
            Any borrower interested in the benefits of an FHA loan and who
            qualifies can get this type of loan. There are no income
            restrictions, and FHA loans are offered to all home buyers, whether
            it's your first time purchasing a home or you've bought a home
            before.
          </p>
        </>
      ),
    },
    {
      question:
        "What if I have a bankruptcy in my past? Can I qualify for an FHA loan?",
      answer: (
        <>
          <p className="mb-0">
            Yes, you can qualify for an FHA loan if you’ve filed for bankruptcy.
            However, there is a waiting period. For a Chapter 7 discharge,
            you’ll need to wait at least two years before applying for an FHA
            loan. For a Chapter 13 bankruptcy, you’ll need to wait one year.
          </p>
        </>
      ),
    },
    {
      question: "Can I get an FHA loan if I’m self-employed?",
      answer: (
        <>
          <p>
            Yes, you can. To qualify, you’ll need to meet these basic
            requirements (in addition to providing a down payment):
          </p>
          <ul className="styled-list-checkmark mb-6">
            <li>Self-employed for at least 2 years</li>
            <li>At least 25% ownership in the business</li>
            <li>Last 2 years’ tax returns to show your income</li>
            <li>A minimum credit score of 500</li>
            <li>No bankruptcies within the past 2 years</li>
          </ul>
          <p>
            You may also be required to show one or more of the following to
            verify your business:
          </p>
          <ul className="styled-list-checkmark">
            <li>Business license</li>
            <li>Online business listing</li>
            <li>Verified physical commercial address</li>
            <li>
              Letter from a CPA verifying how long your business has been in
              operation
            </li>
          </ul>
        </>
      ),
    },
    {
      question: `What types of homes can I buy in ${data.loanProgram.city.title} with an FHA loan?`,
      answer: (
        <>
          <p>
            There are many types of homes that are eligible for FHA financing,
            including a:
          </p>
          <ul className="styled-list-checkmark">
            <li>Single-family home</li>
            <li>Condominium</li>
            <li>Manufactured home</li>
            <li>Fixer-upper</li>
            <li>New construction</li>
            <li>Mixed-use property</li>
          </ul>
        </>
      ),
    },
  ];

  return (
    <Layout>
      <SearchEngineOptimization
        title={data.loanProgram.seoTitle}
        description={data.loanProgram.metaDescription}
        openGraphImage={data.openGraphImage.publicURL}
        twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section className="mb-20 md:mb-28 md:pt-12">
        <div className="container">
          <div className="grid items-center gap-y-10 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
            <div>
              <StaticImage
                src="../images/2.0 Loan Programs/FHA/1.0 fha-hero.png"
                loading="eager"
                width={560}
              />
            </div>
            <div>
              <h1>{data.loanProgram.city.title} FHA Home Loan</h1>
              <p>
                FHA loans are perhaps the most well-known type of loan for
                buying a home. They’re backed by the Federal Housing
                Administration, making them less risky for lenders. And for{" "}
                {data.loanProgram.city.title} home buyers, FHA loans have less
                stringent requirements, making them easier to obtain than other
                loans.
              </p>
              <ButtonSolid
                href="/request-rates/"
                text="Request Rates"
                altStyle={2}
              />
            </div>
          </div>
        </div>
      </section>

      <section className="mb-20 bg-primary-50 py-20 md:mb-32 md:py-32">
        <div className="container">
          <header className="mb-12 md:mb-24">
            <h2>Your Guide to FHA Home Loans</h2>
          </header>

          <div className="grid md:grid-cols-12 md:gap-x-14 lg:gap-x-18">
            <ScrollNavigation className="hidden md:col-span-4 md:col-start-1 md:block">
              <li>
                <AnchorLink
                  to={url + "#section-1"}
                  title="Who it’s For"
                  stripHash
                />
              </li>
              <li>
                <AnchorLink
                  to={url + "#section-2"}
                  title="Benefits"
                  stripHash
                />
              </li>
              <li>
                <AnchorLink
                  to={url + "#section-3"}
                  title="Eligibility"
                  stripHash
                />
              </li>
            </ScrollNavigation>

            <div className="space-y-12 md:col-span-8 md:col-end-13 md:space-y-24">
              <div
                id="section-1"
                className="rounded-3xl bg-white px-6 py-12 shadow-4xl md:p-14"
              >
                <h3 className="heading-four">Who It’s For</h3>
                <p className="mb-0">
                  FHA loans are designed for those with low or moderate income,
                  lower credit scores, and want to buy a home with a lower down
                  payment. For example, borrowers may have limited savings and
                  are moderately credit challenged. Or they may be first-time
                  home buyers attracted by the lower down payment option.
                </p>
              </div>

              <div
                id="section-2"
                className="rounded-3xl bg-white px-6 py-12 shadow-4xl md:p-14"
              >
                <header className="mb-10 md:mb-12">
                  <h3 className="heading-four">
                    The Benefits for {data.loanProgram.city.title} Home Buyers
                  </h3>
                </header>

                <div className="grid gap-8 md:grid-cols-2 md:gap-10">
                  <div>
                    <img
                      src={easierQualification}
                      alt="Easier Qualification"
                      className="mb-2.5"
                    />
                    <h3 className="heading-six">Easier Qualification</h3>
                    <p className="mb-0">
                      You can qualify for an FHA loan with a credit score as low
                      as 500 and, in some cases, a higher debt-to-income (DTI)
                      ratio.
                    </p>
                  </div>

                  <div>
                    <img
                      src={lowDownPayment}
                      alt="Low Down Payment"
                      className="mb-2.5"
                    />
                    <h3 className="heading-six">Low Down Payment</h3>
                    <p className="mb-0">
                      The down payment is 3.5% (if your credit score is 580 or
                      higher) or 10% (if your credit score is 500 to 579).
                    </p>
                  </div>

                  <div>
                    <img
                      src={affordableInterestRates}
                      alt="Affordable Interest Rates"
                      className="mb-2.5"
                    />
                    <h3 className="heading-six">Affordable Interest Rates</h3>
                    <p className="mb-0">
                      Borrowers with lower credit scores can often qualify for
                      the same interest rates as borrowers with higher credit
                      scores.
                    </p>
                  </div>

                  <div>
                    <img
                      src={sellerPaidClosingCosts}
                      alt="Seller-Paid Closing Costs"
                      className="mb-2.5"
                    />
                    <h3 className="heading-six">Seller-Paid Closing Costs</h3>
                    <p className="mb-0">
                      Sellers can pay up to 6 percent of the loan amount for
                      closing costs. Some closing costs can also be rolled into
                      the loan.
                    </p>
                  </div>
                </div>
              </div>

              <div
                id="section-3"
                className="rounded-3xl bg-white px-6 py-12 shadow-4xl md:p-14"
              >
                <h3 className="heading-four">Eligibility Requirements</h3>
                <ul className="styled-list-checkmark">
                  <li>
                    3.5% down payment if your credit score is 580 or higher
                  </li>
                  <li>10% down payment if your credit score is 500 to 579</li>
                  <li>
                    Debt-to-income (DTI) ratio of 43% or lower (can go higher on
                    a case-by-case basis)
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <FAQs heading="FAQs" uniqueFaqs={faqs} />
      <Testimonials />
      <ValueProps />
      <About />
      <RelatedLoanPrograms
        heading="Other Loan Programs"
        hiddenProgram={1}
        location={data.loanProgram.city.title}
      />
      <CallToAction />
    </Layout>
  );
};

export default Template;
